@import '../../shared/colors.scss';

header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;

    .header__container {
        text-align: center;
        height: 100%;
        position: relative;

        h1::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: $color-primary;
  z-index: -1;
  transition: transform .3s ease;
}

h1 {
  position: relative;
  font-size: 5rem;
  display: inline-block;
}
    }

    /* =========== CTA =========== */
    .cta {
        margin-top: 2.5rem;
        display: flex;
        gap:1.2rem;
        justify-content: center;

    }

    /* =========== HEADER SOCIAL =========== */
    .header__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 0;
        bottom: 3rem;
    }

    .header__socials::after {
        content: '';
        width: 1px;
        height: 2rem;
        background-color: $color-primary;
    }


    .me {
        position: relative;
                width: 19rem;
        height: 30rem;
                position: absolute;
        left: calc(50% - 9rem);
        margin-top: 4rem;

        &-image-wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        &::before,
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          border: 2px solid #3e48a1;
          transition: all .25s ease-out;
          
        }
        &::before {
          background-color: #3e48a1;
          top: -15px;
          left: -15px;
        }
        &::after {
          bottom: -15px;
          right: -15px;
        }
        &:hover {
          &::before {
            top: 15px;
            left: 15px;
          }
          &::after {
            bottom: 15px;
            right: 15px;
          }
        }
      }


    /* ==================== SCROLL DOWN ==================== */
    .scroll__down {
        position: absolute;
        right: -2.3rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 0.9rem;
    }
}


/* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
    header {
       height: 90vh;

       .me-image-wrapper {
         img {
          //  margin-top: -10rem;
         }
       }
    }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    header {
       height: 100vh;
        width: 100vw;
        .me-image-wrapper {
          img {
            margin-top: -8rem;
          }
        }
        .header__socials, .scroll__down {
            display: none;
        }
    }
}

/* ================ MEDIA QUERIES(MOBILE DEVICES) ================ */
@media screen and(max-width: 420px) {
    header {
        height: 100vh;
        width: 100vw;
    }
}

/* ================ MEDIA QUERIES(MOBILE DEVICES) ================ */
@media screen and(max-width: 360px) {
    header {
        height: 110vh;
        width: 100vw;
        .me-image-wrapper {
          img {
            // margin-top: -8rem;
          }
        }
    }
}


/* ================ MEDIA QUERIES(MOBILE DEVICES) ================ */
@media screen and(max-width: 290px) {
    header {
        height: 110vh;
        width: 100vw;

    }

    .container {
        width: 100%;
    }
}