@import '../../shared/colors.scss';
@import '../../shared/size.scss';

.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 38% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background-color: $color-bg-variant;
    padding:1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: $transition;

    &:hover {
        border-color: $color-primary-variant;
        background: transparent;
        cursor: default;
    }

    &-icon {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    a {
        margin-top: 0.7rem;
        display: inline-block;
        font-size: 0.8rem;
    }
}


/* =============== FORM ============ */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid $color-primary-variant;
    resize: none;
    color: $color-white;
}


/* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
    .contact__container {
        grid-template-columns: 1fr;
    gap: 2rem;
    }
}
    
    /* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
.contact__container {
    width: $container-width-sm;

}
    }