 @import '../../shared/colors.scss';

 .portfolio__container {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 2.5rem;
 }

 .portfolio__item {
     background-color:$color-bg-variant;
        padding: 1.3rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: $transition;

        &:hover {
            border-color: $color-primary-variant;
            background: transparent;
            cursor: default;
        }

        &-image {
            border-radius: 0.7rem;
            overflow: hidden;
            height: 11rem;

            img {
                height: 100%;
            }
        }

        h4 {
            margin: 1.2rem 0 2rem;
        }

        &-cta {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2.5rem;
            margin: 1rem;
            overflow: hidden;
        }
 }


 /* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
    .portfolio__container {
       grid-template-columns: repeat(2, 1fr);
       gap: 1.2rem;
    }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    .portfolio__container {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        width: 100%;
     }
}
