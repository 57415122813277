@import 'shared/colors.scss';
@import 'shared/size.scss';

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600&family=Russo+One&display=swap');



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}


p, li, span, label, input, textarea {
    font-family: 'Roboto Mono', monospace;
}
::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Roboto Mono', monospace;
    background: $color-bg;
    color: $color-white;
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png);
}


/* ================== GENERAL STYLES ================== */
.container {
    width: $container-width-lg;
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 , strong {
    font-family: 'Russo One', sans-serif;
    font-weight: 400;
}
h1 {
    font-size:2.5rem;
}
section {
    height: max-content;
    margin-top: 8rem;
    
    h2, h5 {
        text-align: center;
        color: $color-light;
    }

    h2 {
        color: $color-primary;
        margin-bottom: 3rem;
    }
}

.text-light {
    color: $color-light;
}

a {
    color: $color-primary;
    transition: $transition;

    &:hover {
        color: $color-white
    }
}


.btn {
    width: max-content;
    display: inline-block;
    color: $color-primary;
    padding: 0.75rem 1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid $color-primary;
    transition: $transition;

    &:hover {
        background: $color-white;
        color: $color-bg;
        border-color: transparent;
    }
}

.btn-primary {
    color: $color-bg;
    background-color: $color-primary;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
    .container {
        width: $container-width-md;
    }

    section {
        margin-top: 6rem;
    }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    .container {
        width: $container-width-sm;
    }

    section {
        width: 100vw;
        h2 {
            margin-top: 2rem;
        }
    }
}

/* ================ MEDIA QUERIES(MOBILE DEVICES) ================ */
@media screen and(max-width: 290px) {
    .container {
        width: 100%;
    }
}