@import '../../shared/colors.scss';
nav {
    background: rgba(0, 0, 0, 0.5);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(12px);

    a{
        background: transparent;
        padding: 0.9rem;
        border-radius: 50%;
        display: flex;
        color: $color-light;
        font-size: 1.1rem;

        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }

        &.active {
            background: $color-primary;
            color: $color-white;
        }
    }

}

