@import '../../shared/colors.scss';

.footer {
    background-color: $color-primary;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;

    a {
        color: $color-bg;
    }

    &__logo {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 2rem;
        display: inline-block;
    }

    .permalinks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        margin: 0 auto 3rem;
        font-size: 1.1rem;

    }

    &__socials {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 4rem;

        a {
            background-color: $color-bg;
            color: $color-white;
            padding: 0.8rem;
            border-radius: 0.7rem;
            display: flex;
            border: 1px solid transparent;

            &:hover {
                background-color: transparent;
                color: $color-bg;
                border-color: $color-bg;
            }
        }
    }

    &__copyright {
        color: $color-bg;
        margin-bottom: 4rem;
        font-size: 1rem;
    }
}


/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    .footer {
    
.permalinks {
    flex-direction: column;
    gap: 1.5rem;
}

&__socials {
    margin-bottom: 2.6rem;
}
     }
}