@import '../../shared/colors.scss';

.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    & > div {
        background-color: $color-bg-variant;
        padding: 2.4rem 5rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: $transition;

        &:hover {
            background-color: transparent;
            border-color: $color-primary-variant;
            cursor: default;
        }

        h3 {
            text-align: center;
            margin-bottom: 2rem;
            color: $color-primary;
        }
    }


    .experience__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2rem;
        column-gap: 2rem;

        .experience__details{
            display: flex;
            gap: 0.5rem;

            &-icon {
                color: $color-primary;
                margin-top: 6px;
            }
        }
    }
}


/* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
.experience__container {
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    width: 100vw;
    grid-template-columns: 1fr;
    gap: 2rem;

    & > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }

    // .experience__backend {
    //     margin-top: 2rem;
    // }
 }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    .experience__container {
    
        & > div {
            width: 95%;
            padding: 2rem;
            margin: 0 auto;
        }
     }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 360px) {
    .experience__container {
    .experience__content {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2rem;
        column-gap: 2rem;
    }
}
}