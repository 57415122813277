@import '../../shared/colors.scss';

.about__container {
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 20%;
}

.about__me {
    width: 100%;
    height: 26rem;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent ,
        $color-primary,
        transparent
    );
    display: grid;
    place-items: center;

    .about__me-image {
        height: 100%;
        width: 100%;
        border-radius: 2rem;
        overflow: hidden;
        transform: rotate(10deg);
        transition: $transition;

        &:hover {
            transform: rotate(0deg);
        }

        img {
            height: 100%;
        }
    }
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;

    .about__card {
        background: $color-bg-variant;
        border: 1px solid transparent;
        border-radius: 1rem;
        padding: 2rem;
        text-align: center;
        transition: $transition;

        &:hover {
            background: transparent;
            border-color: $color-primary-variant;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
            cursor: default;
        }

        .about__icon {
            color: $color-primary;
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        h5 {
            font-size: 0.95rem;
        }

        small {
            font-size: 0.7rem;
            color: $color-light;
        }
    }
}

.about__content {
    // display: block;
    p {
        margin: 2rem 0 2.6rem;
        color: $color-light;
    }

    .btn-container {
        display: grid;
        place-items: center;;
    }
}


/* ================ MEDIA QUERIES(MEDIUM DEVICES) ================ */
@media screen and(max-width: 1024px) {
    .about__container {
       grid-template-columns: 1fr;
       gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content {
        p {
            margin: 1rem 0 1.5rem;
        }
    }
}

/* ================ MEDIA QUERIES(SMALL DEVICES) ================ */
@media screen and(max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
        height: 40vh;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;

        p {
            margin: 1.5rem 0.5rem;
        }
    }
}